import React from 'react';
// import { Carousel } from 'react-bootstrap';

function BusinessBanner() {
    
    return (
        <>
	    <div className="banner">
            <img
                src='../../assets/images/process.jpg'
                alt="Responsive Banner"
                className="bannerImage"
            />
        </div>
        </>
    )
}

export default BusinessBanner
