import React from 'react';
import { Link } from 'react-router-dom';
// import { Carousel } from 'react-bootstrap';

function SubsBanner() {
    
    return (
        <>
		<div className="banner">
            <img
                src='../../assets/images/subscription.jpg'
                alt="Responsive Banner"
                className="bannerImage"
            />
        </div>
        </>
    )
}

export default SubsBanner
