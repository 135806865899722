import React from 'react';
// import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function HealthcareBanner() {
    
    return (
        <>
		<div className="banner">
            <img
                src='../../assets/images/health.jpg'
                alt="Responsive Banner"
                className="bannerImage"
            />			
        </div>	
            

        </>
    )
}

export default HealthcareBanner
