import React from 'react';
import ReactPlayer from 'react-player';

function Dashboard() {
    return (
        <>

            <section id="services" className="pricing sec-height">
                <div className="container">

                    <div className="section-title" >
                        <h2>Dashboard</h2>
                    </div>

                    
                </div>
            </section>

        </>
    )
}

export default Dashboard
