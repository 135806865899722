import React from 'react';
import { Link } from 'react-router-dom';
// import { Carousel } from 'react-bootstrap';

function PriceBanner() {

	return (
		<>

		<div className="banner">
            <img
                src='../../assets/images/pricing&plan.jpg'
                alt="Responsive Banner"
                className="bannerImage"
            />
        </div>

			



		</>
	)
}

export default PriceBanner
