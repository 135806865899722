import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {authCheckState, authLogout} from '../../UserAuth/store/actions/auth'

class Top2 extends Component {

    
    render() {
    return (
        <>
            <header >
                
            </header>
        </>
    )
}
}



export default Top2;
