import React from 'react';
// import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function ReferBanner() {
    
    return (
        <>

		<div className="banner">
            <img
                src='../../assets/images/refer.jpg'
                alt="Responsive Banner"
                className="bannerImage"
            />
        </div>
            

        </>
    )
}

export default ReferBanner
