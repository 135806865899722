import React from 'react';
// import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function BookKeepBanner() {
    
    return (
        <>

            
        <div className="banner">
            <img
                src='../../assets/images/bookkeep.jpg'
                alt="Responsive Banner"
                className="bannerImage"
            />
        </div>
            

        </>
    )
}

export default BookKeepBanner
