import React from 'react';
// import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function WebsiteBanner() {
    
    return (
        <>

        <div className="banner">
            <img
                src='../../assets/images/website.jpg'
                alt="Responsive Banner"
                className="bannerImage"
            />			
        </div>
            
            

        </>
    )
}

export default WebsiteBanner
